// Generated by Framer (28b2919)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["k2GRFvlBk", "XrCHFw43r"];

const variantClassNames = {k2GRFvlBk: "framer-v-frsjmd", XrCHFw43r: "framer-v-18ig1pn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Burger: "k2GRFvlBk", X: "XrCHFw43r"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, iWF8EWIN1: color ?? props.iWF8EWIN1 ?? "rgb(136, 136, 136)", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "k2GRFvlBk"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, iWF8EWIN1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "k2GRFvlBk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap17gqau7 = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("XrCHFw43r")
})

const onTap1jriw7l = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("k2GRFvlBk")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6NVhr", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-frsjmd", className)} data-framer-name={"Burger"} data-highlight layoutDependency={layoutDependency} layoutId={"k2GRFvlBk"} onTap={onTap17gqau7} ref={ref} style={{...style}} {...addPropertyOverrides({XrCHFw43r: {"data-framer-name": "X", onTap: onTap1jriw7l}}, baseVariant, gestureVariant)}><motion.div className={"framer-174fvkm"} layoutDependency={layoutDependency} layoutId={"BwMTzSYFZ"}><motion.div className={"framer-k57oan"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"B9SsG8N9Y"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{XrCHFw43r: {rotate: -45}}}/><motion.div className={"framer-167kemw"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"Ao1vr0HNL"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} variants={{XrCHFw43r: {opacity: 0}}}/><motion.div className={"framer-1s2c9fz"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"tK3NceKfI"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{XrCHFw43r: {rotate: 45}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6NVhr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6NVhr .framer-1og85h4 { display: block; }", ".framer-6NVhr .framer-frsjmd { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-6NVhr .framer-174fvkm { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-6NVhr .framer-k57oan { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-6NVhr .framer-167kemw { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-6NVhr .framer-1s2c9fz { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-6NVhr.framer-v-18ig1pn .framer-k57oan { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-6NVhr.framer-v-18ig1pn .framer-167kemw { left: unset; width: 2px; }", ".framer-6NVhr.framer-v-18ig1pn .framer-1s2c9fz { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XrCHFw43r":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const FramerSin2h8MNj: React.ComponentType<Props> = withCSS(Component, css, "framer-6NVhr") as typeof Component;
export default FramerSin2h8MNj;

FramerSin2h8MNj.displayName = "Elements/Menu Icon";

FramerSin2h8MNj.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerSin2h8MNj, {variant: {options: ["k2GRFvlBk", "XrCHFw43r"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerSin2h8MNj, [])